import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../../static/images/logo/logo-only.svg"

const Success = () => {
    return (
        <Layout page="Success"> 
            <Seo title="ありがとうございます"
                 description="ありがとうございます"
                 noindex={true} 
            />
            <div className="container success" style={{textAlign: "center", margin: "140px auto 100px auto"}}>
                <Link to="/" title="monotein">
                    <img alt="Logo" src={Logo} style={{width: "80px"}}/>
                </Link>
                <h3 style={{margin: "24px 0 10px" }}>ありがとうございます。</h3>
                <p style={{marginBottom: "25px" }}>2営業日以内にご返信いたします。</p>
                <div><Link to="/">最初のページに戻る</Link></div>
            </div>
        </Layout>
    )
}

export default Success
